import { Popup, PopupProperties } from 'components/Popup';
import { RichText } from 'components/RichText';
import { ButtonLink } from 'components/Button';
import styles from './MultipleHousePopup.module.css';

type Properties = Omit<PopupProperties, 'children'>;

export const MultipleHousePopup = ({ onClose, ...rest }: Properties) => (
  <Popup onClose={onClose} {...rest}>
    <div className={styles.inner}>
      <RichText>
        <h2>Meerdere woningen</h2>
        <p>
          Het is niet mogelijk om een berekening te maken voor meerdere woningen. Graag verwijzen we je naar Previcus
          waar je wel bezwaar kunt laten maken.
        </p>
      </RichText>
      <div className={styles.buttonContainer}>
        <ButtonLink theme="primary" icon="ArrowRightIcon" href="https://www.previcus.nl/">
          Naar de website van Previcus
        </ButtonLink>
        <ButtonLink theme="primary" icon="ArrowRightIcon" href="https://juistwoz.nl/">
          Terug naar de homepage
        </ButtonLink>
      </div>
    </div>
  </Popup>
);
