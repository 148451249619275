import { RadioGroup } from 'components/RadioGroup';
import { useFormContext } from 'react-hook-form';
import styles from './LivingSituation.module.css';
import { AnimatePresence, motion } from 'framer-motion';
import { Input } from 'components/Input';
import { InputGroup } from './InputGroup';
import { STEPS } from 'components/constants/STEPS';
import { getErrorFromFormState } from 'components/helpers/getErrorFromFormState';

const LIVING_SITUATION_OPTIONS = [
  {
    label: 'Mijn eigen woning',
    value: 'owner',
  },
  {
    label: 'Ik huur de woning',
    value: 'landlord',
  },
  {
    label: 'Mijn tweede woning',
    value: 'second-house',
  },
  {
    label: 'Erfenis',
    value: 'heir',
  },
  {
    label: 'Ik heb meerdere woningen',
    value: 'multiple-houses',
  },
];

const PURCHASED_AFTER_OPTIONS = [
  {
    label: 'Ja',
    value: 'yes',
  },
  {
    label: 'Nee',
    value: 'no',
  },
];

export const LivingSituation = () => {
  const { watch, setValue, register, formState } = useFormContext<any>();

  const [
    livingSituationFieldName,
    isTransferredAfter2020FieldName,
    transferDateFieldName,
    transferPriceFieldName,
    renovationPriceFieldName,
  ] = STEPS[1].subSteps[0].fields;

  const [relationshipToProperty, isTransferredAfter2020] = [
    watch('livingSituation.relationshipToProperty'),
    watch('livingSituation.isTransferredAfter2020'),
  ];

  return (
    <div className={styles.container}>
      <RadioGroup
        label="Wat is je woningsituatie?"
        error={getErrorFromFormState(formState, livingSituationFieldName)}
        name="livingSituation.relationshipToProperty"
        options={LIVING_SITUATION_OPTIONS}
        value={relationshipToProperty}
        onChange={(event) => setValue('livingSituation.relationshipToProperty', event.target.value)}
      />
      <AnimatePresence mode="wait">
        <motion.div
          className={styles.container}
          key={!!relationshipToProperty && relationshipToProperty !== 'landlord' ? 'noRental' : 'rental'}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.1 }}>
          {relationshipToProperty !== 'landlord' &&
            relationshipToProperty !== 'multiple-houses' &&
            !!relationshipToProperty && (
              <RadioGroup
                label={`Heb je de woning aangekocht na 1 januari ${new Date().getFullYear() - 3}?`}
                error={getErrorFromFormState(formState, isTransferredAfter2020FieldName)}
                name="livingSituation.isTransferredAfter2020"
                options={PURCHASED_AFTER_OPTIONS}
                value={isTransferredAfter2020}
                onChange={(event) => setValue('livingSituation.isTransferredAfter2020', event.target.value)}
              />
            )}
        </motion.div>
      </AnimatePresence>
      <AnimatePresence mode="wait">
        <motion.div
          className={styles.container}
          key={isTransferredAfter2020}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.1 }}>
          {isTransferredAfter2020 === 'yes' &&
            relationshipToProperty !== 'landlord' &&
            relationshipToProperty !== 'multiple-houses' && (
              <InputGroup>
                <Input
                  error={getErrorFromFormState(formState, transferDateFieldName)}
                  description="Op welke datum heb je de woning aangekocht?"
                  type="date"
                  min={`${new Date().getFullYear() - 3}-01-01`}
                  {...register('livingSituation.transferDate')}
                />
                <Input
                  step={50}
                  after="CurrencyEuroIcon"
                  description="Voor welk bedrag heb je de woning aangekocht?"
                  type="number"
                  value={watch('livingSituation.transferPrice')}
                  error={getErrorFromFormState(formState, transferPriceFieldName)}
                  onChange={(event) => setValue('livingSituation.transferPrice', event.target.value)}
                />
                <Input
                  step={50}
                  after="CurrencyEuroIcon"
                  description="Voor welk bedrag is de woning verbouwd?"
                  type="number"
                  value={watch('livingSituation.renovationPrice')}
                  error={getErrorFromFormState(formState, renovationPriceFieldName)}
                  onChange={(event) => setValue('livingSituation.renovationPrice', event.target.value)}
                />
              </InputGroup>
            )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
