import { Popup, PopupProperties } from 'components/Popup';
import { RichText } from 'components/RichText';
import { Button, ButtonLink } from 'components/Button';
import styles from './ConfirmAddressPopup.module.css';

type Properties = Omit<PopupProperties, 'children'> & { onConfirm?: () => void; address?: string };

export const ConfirmAddressPopup = ({ address, onClose, onConfirm, ...rest }: Properties) => (
  <Popup onClose={onClose} {...rest}>
    <div className={styles.inner}>
      <RichText>
        <h2>Klopt het adres waarvoor je de WOZ-scan wilt starten?</h2>
        <p>
          Het ingevoerde adres is: <strong>{address || 'onbekend'}</strong>
        </p>
      </RichText>
      <div className={styles.buttonContainer}>
        {onConfirm ? (
          <Button theme="cta" onClick={onConfirm}>
            JUIST! Start de scan
          </Button>
        ) : undefined}
        <ButtonLink theme="secondary" icon="ArrowRightIcon" href="https://juistwoz.nl">
          Adres opnieuw invoeren
        </ButtonLink>
      </div>
    </div>
  </Popup>
);
