import { InputContainer, InputContainerProperties } from './InputContainer';
import React, { forwardRef, InputHTMLAttributes, useMemo } from 'react';
import styles from './Input.module.css';
import { IconType } from 'components/helpers/types';

export interface InputProperties
  extends Omit<InputContainerProperties, 'children'>,
    InputHTMLAttributes<HTMLInputElement> {
  type?: 'date' | 'email' | 'number' | 'password' | 'tel' | 'text' | 'file' | 'hidden';
  after?: string | IconType;
}

export const Input = forwardRef<HTMLInputElement, InputProperties>(
  ({ description, error, label, after, required, type = 'text', info, ...rest }, reference) => {
    const classNames = useMemo(() => (error ? `${styles.input} ${styles.error}` : styles.input), [error]);

    return (
      <InputContainer
        after={after}
        description={description}
        error={error}
        info={info}
        label={label}
        required={required}>
        <input className={classNames} type={type} ref={reference} {...rest} />
      </InputContainer>
    );
  }
);

Input.displayName = 'Input';
