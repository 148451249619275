/* eslint-disable react/jsx-no-bind */
/* eslint-disable unicorn/prefer-at */
import { Input } from 'components/Input';
import React, { ChangeEvent, Fragment, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseFormSetValue, useFormContext } from 'react-hook-form';
import cx from 'classnames';
import styles from './AddressLookup.module.css';
import { focusNextElement } from './helpers/focusNextElement';
import { AnimatePresence, motion } from 'framer-motion';
import { ApiError, PDOKResponseItem, useAddress, validateAddress } from 'components/helpers/api';
import { captureException } from 'components/helpers/captureException';
import { useDebounce } from 'hooks/useDebounce';
import { AddressHasNoLivingPurpose } from 'components/AddressHasNoLivingPurpose';
import { Button, ButtonLink } from 'components/Button';
import { setProgress } from 'components/helpers/progress';
import { AllFields } from 'components/helpers/types';
import { VerticalMargin } from 'components/VerticalMargin';
import { RichText } from 'components/RichText';
import { useRouter } from 'next/router';
import { useSteps } from 'context/StepsProvider';
import Link from 'next/link';

const DEFAULT_NOT_FOUND_ADDRESS_ERROR = 'We hebben nu geen resultaat gevonden voor dit adres';

const DEFAULT_ERROR_NO_ZIPCODE =
  'We konden op basis van deze gegevens geen adres vinden. Zorg dat het geselecteerde adres in ieder geval een postcode en huisnummer bevat.';

interface Properties extends HTMLAttributes<HTMLInputElement> {
  setValue?: UseFormSetValue<any>;
  fieldName: string;
  initialValue?: string;
  addressError?: string;
}

export const AddressLookup = ({ initialValue, setValue, fieldName, addressError }: Properties) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [showOptions, setShowOptions] = useState(false);
  const [validationError, setValidationError] = useState<string>();
  const [addressHasLivingPurpose, setAddressHasLivingPurpose] = useState(true);
  const [showInputField, setShowInputField] = useState(true);

  const containerReference = useRef<HTMLDivElement>(null);
  const addressInputReference = useRef<HTMLInputElement>(null);

  const router = useRouter();

  const { watch } = useFormContext<Partial<AllFields>>();

  const { resetScan } = useSteps();

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (containerReference.current && !containerReference.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerReference]);

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'ArrowDown') {
        focusNextElement('forward');
      }
      if (event.key === 'ArrowUp') {
        focusNextElement('backward');
      }
    }

    document.addEventListener('keydown', onKeyDown, false);

    return () => document.removeEventListener('keydown', onKeyDown, false);
  }, []);

  const handleInputFocus = useCallback(() => {
    if (validationError?.includes(DEFAULT_NOT_FOUND_ADDRESS_ERROR)) {
      return;
    }
    setShowOptions(true);
    setSearchTerm(undefined);
  }, [validationError]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (router.query.selected_address) {
        router.replace(router.pathname, undefined, { scroll: false, shallow: true });
      }
      setShowOptions(true);
      setValidationError(undefined);
      setSearchTerm(event.target.value);
    },
    [router]
  );

  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const { data, error } = useAddress({
    searchString: debouncedSearchTerm,
    enabled: !!debouncedSearchTerm && debouncedSearchTerm.length > 3,
  });

  const dataOptions = useMemo(() => {
    const onlyAddressOptions = data?.response?.docs?.filter((address) => address.type === 'adres');
    return error
      ? undefined
      : onlyAddressOptions?.map((address) => ({
          label: address.weergavenaam,
          value: address.weergavenaam,
        }));
  }, [data?.response?.docs, error]);

  const handleChange = useCallback(
    async (selectedOption: string, prefilledSelectedAddress?: PDOKResponseItem) => {
      resetScan();
      const selectedOptionLabel = dataOptions?.find((option) => option.value === selectedOption)?.label;
      const selectedAddress =
        prefilledSelectedAddress ?? data?.response?.docs?.find((address) => address.weergavenaam === selectedOption);

      if (router.asPath === '/embed' && !!process.env.NEXT_PUBLIC_PRODUCTION_URL) {
        const selectedAddressJSON = JSON.stringify(selectedAddress);
        const urlEncodedAddress = encodeURIComponent(selectedAddressJSON);
        const url = `${process.env.NEXT_PUBLIC_PRODUCTION_URL}?selected_address=${urlEncodedAddress}&selected_option=${selectedOption}`;
        window.open(url, '_blank');
        return;
      }

      try {
        const response = await validateAddress({
          house_number: selectedAddress?.huisnummer?.toString(),
          postcode: selectedAddress?.postcode,
          addition: selectedAddress?.huisnummertoevoeging,
          letter: selectedAddress?.huisletter,
        });

        const hasLivingPurpose = response?.purposes.some(
          (purpose) => purpose.toLowerCase() === 'woonfunctie' || purpose.toLowerCase() === 'logiesfunctie'
        );

        if (!hasLivingPurpose) {
          setShowInputField(false);
          setAddressHasLivingPurpose(hasLivingPurpose);
          setProgress({ step: 0, subStep: 0, fieldValues: watch() });
        }

        if (hasLivingPurpose) {
          setProgress({ step: 0, subStep: 0, fieldValues: watch() });
        }

        setSearchTerm(selectedOptionLabel);

        !!fieldName && setValue?.(fieldName, selectedOption);

        setValue?.('addressDetails.house_number', response.streetNumber);
        setValue?.('addressDetails.postcode', response.postalCode);
        //This looks like a mix-up of the house number and the house number addition, but it's not.
        //The API expects the house number in the house_number field and the house number addition in the addition field.
        setValue?.('addressDetails.house_number_addition', selectedAddress?.huisnummertoevoeging);
        setValue?.('addressDetails.house_letter', selectedAddress?.huisletter);

        if (response?.surfaceArea) {
          setValue?.('charactaristics.useSurface', response.surfaceArea);
        }
        setShowOptions(false);
      } catch (_error) {
        setValue?.(fieldName, undefined);
        if (_error instanceof ApiError && _error.body.detail?.toLowerCase().includes('no results found')) {
          setSearchTerm(selectedOptionLabel);
          setValidationError(DEFAULT_NOT_FOUND_ADDRESS_ERROR);
          captureException(_error, { tags: { fn: 'validateAddress.NoResults' } });
          setShowOptions(false);
          setShowOptions(false);
          return;
        }

        if (
          _error instanceof ApiError &&
          _error.body.detail?.toLowerCase().includes('requires parameter "postcode" to be present')
        ) {
          setSearchTerm(selectedOptionLabel);
          setValidationError(DEFAULT_ERROR_NO_ZIPCODE);
          setShowOptions(false);
          return;
        } else {
          setValidationError('Er is iets mis gegaan, probeer het later opnieuw');
          captureException(_error, { tags: { fn: 'validateAddress' } });
          setShowOptions(false);
          return;
        }
      }
    },
    [dataOptions, data?.response?.docs, router, fieldName, setValue, watch]
  );

  useEffect(() => {
    if (router.asPath === '/embed' || !router.query.selected_address) return;

    const selectedAddress = decodeURIComponent(router.query.selected_address as string);
    const selectedOption = router.query.selected_option as string;

    if (!selectedAddress || !selectedOption) return;

    handleChange(selectedOption, JSON.parse(selectedAddress));
  }, [fieldName, handleChange, router.asPath, router.query.selected_address, router.query.selected_option, setValue]);

  const onShowInputField = useCallback(() => {
    setAddressHasLivingPurpose(true);
    setShowInputField(true);
  }, []);

  const onClearAddressInput = useCallback(() => {
    if (router.query.selected_address) {
      router.replace(router.pathname, undefined, { scroll: false, shallow: true });
    }

    onShowInputField();
    setValidationError(undefined);
    setShowOptions(false);
    setSearchTerm(undefined);

    if (addressInputReference.current) {
      addressInputReference.current.value = '';
      addressInputReference.current.focus();
      setShowOptions(true);
    }
  }, [onShowInputField, router]);

  return (
    <div ref={containerReference}>
      {!addressHasLivingPurpose && (
        <div className={styles.addressErrorContainer}>
          <AddressHasNoLivingPurpose h1="" h2="Je hebt een adres ingevoerd waar geen woonbestemming op zit" />
          <Button onClick={onClearAddressInput}>Ander adres invoeren</Button>
        </div>
      )}
      {!!showInputField && (
        <div className={styles.inputContainer}>
          <Input
            label="Vul je postcode en huisnummer in"
            error={error ? validationError : undefined}
            value={searchTerm ?? initialValue ?? ''}
            type="text"
            placeholder="Postcode + huisnummer"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            ref={addressInputReference}
          />
          {!!addressError && <span className={styles.error}>{addressError}</span>}
          {!!validationError && validationError !== DEFAULT_NOT_FOUND_ADDRESS_ERROR && (
            <span className={styles.error}>{validationError}</span>
          )}
          {!!validationError?.includes(DEFAULT_NOT_FOUND_ADDRESS_ERROR) && (
            <Fragment>
              <VerticalMargin size="medium" />
              <div className={styles.notFound}>
                <RichText center>
                  <h2>De juiste WOZ-waarde kan nog niet berekend worden</h2>
                  <p>
                    Je gemeente heeft de WOZ-waarde van 2025 nog niet bekend gemaakt. Laat je gegevens achter en we
                    sturen een e-mail wanneer we jouw juiste WOZ-waarde kunnen bepalen. Het kan ook zijn dat de scan je
                    WOZ-waarde niet op kan halen. Heb je een aanslagbiljet met WOZ-waarde ontvangen en wil je bezwaar
                    maken? Mail dan een kopie of foto van het aanslagbiljet naar{' '}
                    <Link href="mailto:maakbezwaar@juistwoz.nl">maakbezwaar@juistwoz.nl</Link> en wij gaan voor jou aan
                    de slag!
                  </p>
                </RichText>

                <div className={styles.addressErrorContainer}>
                  <VerticalMargin size="small" />
                  <ButtonLink href="https://juistwoz.nl/woz-wekker/" theme="cta">
                    Gegevens achterlaten
                  </ButtonLink>
                  <div className={styles.seperator}>of</div>
                  <Button onClick={onClearAddressInput} theme="secondary">
                    Vul een ander adres in
                  </Button>
                </div>
              </div>
            </Fragment>
          )}

          {!!validationError?.includes(DEFAULT_ERROR_NO_ZIPCODE) && (
            <div className={styles.addressErrorContainer}>
              <VerticalMargin size="small" />
              <Button onClick={onClearAddressInput}>Voer een ander adres in</Button>
            </div>
          )}
          <AnimatePresence mode="wait">
            <motion.div
              key="dropdownList"
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 10, opacity: 0 }}
              transition={{ duration: 0.4 }}>
              {!!debouncedSearchTerm && debouncedSearchTerm.length > 3 && (
                <ul
                  className={cx(styles.options, {
                    [styles.optionsIsVisible]: showOptions,
                    [styles.isEmbed]: router.asPath === '/embed',
                  })}>
                  {dataOptions?.map((option) => (
                    <li key={option?.value}>
                      <button
                        type="button"
                        onClick={() => handleChange(option.value)}
                        className={styles.option}
                        onFocus={() => setShowOptions(true)}>
                        {option.label}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};
