import { Form } from 'components/Form';
import { RichText } from 'components/RichText';
import React, { useCallback, useRef, useState } from 'react';
import styles from './PersonalDataPopup.module.css';
import { captureException } from 'components/helpers/captureException';
import { sendSignature } from 'components/helpers/api';
import { deleteProgress } from 'components/helpers/progress';
import { deleteWozUuid, getWozResult } from 'components/helpers/cookies';
import { useRouter } from 'next/router';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from 'components/Button';
import { VerticalMargin } from 'components/VerticalMargin';
import { useForm } from 'react-hook-form';
import { Input } from 'components/Input';
import { InputContainer } from 'components/InputContainer';
import { trackEvent } from 'components/helpers/tracking';
import Link from 'next/link';

type SignatureFormValues = {
  taxpayerFirstName?: string;
  taxpayerSurname?: string;
};

export const SignatureForm = ({
  defaultValuesFromSubmit,
}: {
  defaultValuesFromSubmit: { firstName?: string; surname?: string };
}) => {
  const [formError, setFormError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const canvasReference = useRef<SignatureCanvas>(null);

  const { register, handleSubmit, formState } = useForm<SignatureFormValues>({
    defaultValues: {
      taxpayerFirstName: defaultValuesFromSubmit?.firstName,
      taxpayerSurname: defaultValuesFromSubmit?.surname,
    },
  });

  const router = useRouter();

  const onSubmitSignature = useCallback(
    async (data: SignatureFormValues) => {
      setFormError(undefined);

      if (canvasReference.current?.isEmpty()) {
        setFormError('Je moet een handtekening zetten');
        return;
      }

      const intakeUuid = getWozResult()?.uuid;

      if (!intakeUuid) {
        captureException(new Error('No intakeUuid found in intake step'), {
          tags: { fn: 'PersonalDataPopup.onSubmitSignature' },
        });
        setFormError('Er is iets misgegaan, probeer het later opnieuw.');
        return;
      }

      setIsSubmitting(true);

      let signature: string | undefined;

      try {
        signature = canvasReference.current?.toDataURL('image/png');
      } catch (error) {
        captureException(error, { tags: { fn: 'PersonalDataPopup.onSubmit.getSignatureUrl' } });
      }

      try {
        if (!!signature && !!intakeUuid && !!data.taxpayerFirstName && !!data.taxpayerSurname) {
          const response = await sendSignature({
            taxpayerFirstName: data.taxpayerFirstName,
            taxpayerSurname: data.taxpayerSurname,
            uuid: intakeUuid,
            signature,
          });

          trackEvent('submitSignature');

          deleteProgress();
          deleteWozUuid();
          router.push(response.redirectUrl || 'https://juistwoz.nl');
        }

        if (!signature || !intakeUuid || !data.taxpayerFirstName || !data.taxpayerSurname) {
          throw new Error('Missing data in signature form');
        }
      } catch (error) {
        captureException(error, { tags: { fn: 'PersonalDataPopup.onSubmit' } });
        setFormError('Er is iets misgegaan, probeer het later opnieuw.');
      } finally {
        setIsSubmitting(false);
      }
    },
    [router]
  );

  return (
    <Form onSubmit={handleSubmit(onSubmitSignature)} error={formError} isSubmitting={isSubmitting}>
      <RichText>
        <h2>Ondertekenen</h2>
        <p>Vul de gegevens in van de belastingplichtige van dit adres en klik op verzenden</p>
      </RichText>
      <Input
        required
        error={formState?.errors?.taxpayerFirstName?.message?.toString()}
        description="Voornaam belastingplichtige"
        placeholder="Voornaam"
        {...register('taxpayerFirstName')}
      />
      <Input
        required
        error={formState?.errors?.taxpayerSurname?.message?.toString()}
        description="Achternaam belastingplichtige"
        placeholder="Achternaam"
        {...register('taxpayerSurname')}
      />
      <VerticalMargin size="small" />
      <InputContainer description="Handtekening belastingplichtige" required>
        <div className={styles.signatureCanvas}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <SignatureCanvas ref={canvasReference} penColor="black" canvasProps={{ height: 300 }} />
        </div>
      </InputContainer>
      <Link
        className={styles.link}
        target="_blank"
        href="https://juistwoz.nl/app/uploads/2025/01/Machtiging-JUIST-2025.pdf">
        Hier kun je de volledige machtiging lezen.
      </Link>
      <VerticalMargin />
      <Button onClick={() => canvasReference.current?.clear()} icon="XMarkIcon" theme="secondary">
        Opnieuw beginnen
      </Button>
    </Form>
  );
};
