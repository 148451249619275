import { ReactNode, useCallback, useMemo, useState } from 'react';
import styles from './InputContainer.module.css';
import { HeroIcon } from 'components/HeroIcon';
import { IconType } from 'components/helpers/types';
import { HERO_ICONS_OPTIONS } from 'components/helpers/HERO_ICONS_OPTIONS';

export interface InputContainerProperties {
  children: ReactNode;
  description?: string;
  error?: string;
  label?: string;
  required?: boolean;
  after?: string | IconType;
  info?: string;
}

export const InputContainer = ({ children, description, error, label, after, info }: InputContainerProperties) => {
  const [showInfo, setShowInfo] = useState(false);

  const afterType = useMemo(() => {
    if (!after) return;
    return HERO_ICONS_OPTIONS.includes(after as IconType) ? 'icon' : 'string';
  }, [after]);

  const onInfoMouseOver = useCallback(() => {
    setShowInfo(true);
  }, []);

  const onMouseOut = useCallback(() => {
    setShowInfo(false);
  }, []);

  return (
    <div>
      <div className={styles.labelContainer}>
        {!!label && <label>{label}</label>}
        {info ? (
          <button
            type="button"
            className={styles.info}
            onFocus={onInfoMouseOver}
            onMouseOver={onInfoMouseOver}
            onBlur={onMouseOut}
            onMouseOut={onMouseOut}>
            <span className={showInfo ? styles.infoActive : undefined}>{info}</span>
            <HeroIcon icon="InformationCircleIcon" outlineStyle="outline" />
          </button>
        ) : undefined}
      </div>
      {!!description && <div className={styles.description}>{description}</div>}
      <div className={styles.childContainer}>
        {children}
        {afterType === 'string' ? (
          <span>{after}</span>
        ) : afterType === 'icon' ? (
          <HeroIcon icon={after as IconType} />
        ) : undefined}
      </div>
      {!!error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
