import { ButtonLink } from 'components/Button';
import { ConfirmAddressPopup } from 'components/ConfirmAddressPopup';
import { FormProvider } from 'react-hook-form';
import { MultipleHousePopup } from 'components/MultipleHousePopup';
import { PersonalDataPopup } from 'components/PersonalDataPopup';
import { Popup } from 'components/Popup';
import { PopupType, useSteps } from 'context/StepsProvider';
import { RichText } from 'components/RichText';
import { Step } from 'components/helpers/types';
import { StepContainer } from './StepContainer';
import { StepperSidebar } from './StepperSidebar';
import { VerticalMargin } from 'components/VerticalMargin';
import styles from './Stepper.module.css';

export const Stepper = () => {
  const {
    currentStep,
    CurrentSubStep,
    currentStepIndex,
    currentSubStepIndex,
    hasPreviousStep,
    hasNextStep,
    popup,
    isNextStepDisabled,
    formMethods,
    handleGoToStep,
    handlePreviousStep,
    handleNextStep,
  } = useSteps();

  return (
    <FormProvider {...formMethods}>
      <div className={styles.container}>
        <StepperSidebar goToStep={handleGoToStep} currentStepIndex={currentStepIndex} />
        <StepContainer
          isNextButtonDisabled={isNextStepDisabled}
          currentStep={currentStep}
          currentStepIndex={currentStepIndex}
          currentSubStepIndex={currentSubStepIndex}
          hasPreviousStep={hasPreviousStep}
          goToPreviousStep={handlePreviousStep}
          goToNextStep={handleNextStep}
          hasNextStep={hasNextStep}>
          <CurrentSubStep />
        </StepContainer>
        {maybeRenderPopup(currentStep, popup)}
      </div>
    </FormProvider>
  );
};

function maybeRenderPopup(currentStep: Step, popup?: PopupType) {
  switch (popup?.type) {
    case 'personalData': {
      return <PersonalDataPopup onClose={popup?.onClose} />;
    }
    case 'confirmAddress': {
      return <ConfirmAddressPopup address={popup?.address} onConfirm={popup?.onConfirm} onClose={popup?.onClose} />;
    }
    case 'multipleHouse': {
      return <MultipleHousePopup onClose={popup?.onClose} />;
    }
    case 'popup': {
      return (
        <Popup onClose={popup?.onClose}>
          <RichText>
            <h2 className={styles.title}>{currentStep.personalDataPopup?.title}</h2>
            <p>{currentStep.personalDataPopup?.description}</p>
          </RichText>
          <VerticalMargin size="small" />
          <ButtonLink theme="primary" icon="ArrowRightIcon" href="https://juistwoz.nl/">
            Terug naar de homepage
          </ButtonLink>
        </Popup>
      );
    }
    default: {
      return null;
    }
  }
}
