import { AddressDetails } from 'components/AddressDetails';
import { Calculation } from 'components/Calculation';
import { Charactaristics } from 'components/Charactaristics';
import { HouseType } from 'components/HouseType';
import { LivingSituation } from 'components/LivingSituation';
import { MaintananceLevel } from 'components/MaintananceLevel';
import { Result } from 'components/Result';
import { Sustainability } from 'components/Sustainability';
import { Step } from 'components/helpers/types';
import { mixed, number, object, string } from 'yup';

export const STEPS: Step[] = [
  {
    id: 'addressDetails',
    title: 'Adresgegevens',
    subTitle: 'Waar staat je woning?',
    subSteps: [{ component: AddressDetails, fields: ['addressDetails.address'] }],
    icon: 'MapPinIcon',
  },
  {
    id: 'livingSituation',
    title: 'Woningsituatie',
    subTitle: 'Wat is de situatie van de woning en het woningtype?',
    subSteps: [
      {
        component: LivingSituation,
        fields: [
          'livingSituation.relationshipToProperty',
          'livingSituation.isTransferredAfter2020',
          'livingSituation.transferDate',
          'livingSituation.transferPrice',
          'livingSituation.renovationPrice',
        ],
      },
      {
        component: HouseType,
        fields: ['livingSituation.houseType'],
      },
    ],
    personalDataPopup: {
      fields: [],
      description: `Het is niet mogelijk om een berekening te maken voor een huurwoning.`,
      title: 'Huurders',
    },
    icon: 'HomeIcon',
  },
  {
    id: 'charactaristics',
    title: 'Kenmerken woning',
    subTitle: 'Wat is de woonoppervlakte en leeftijd van de voorzieningen?',
    subSteps: [
      {
        component: Charactaristics,
        fields: ['charactaristics.useSurface', 'charactaristics.bathroomAge', 'charactaristics.kitchenAge'],
      },
    ],
    icon: 'ListBulletIcon',
  },
  {
    id: 'quality',
    title: 'Staat van de woning',
    subTitle: 'Is je woning goed onderhouden en duurzaam?',
    subSteps: [
      { component: Sustainability, fields: ['quality.houseDurability'] },
      { component: MaintananceLevel, fields: ['quality.houseMaintenanceLevel'] },
    ],
    icon: 'StarIcon',
  },
  {
    id: 'calculation',
    title: 'Berekenen juiste WOZ-waarde',
    componentTitle: 'Een moment geduld',
    subTitle: 'We berekenen nu jouw verwachte WOZ-waarde',
    subSteps: [{ component: Calculation, fields: [] }],
    icon: 'ClipboardDocumentCheckIcon',
  },
  {
    id: 'result',
    title: 'Resultaat WOZ-Scan', //these are needed for tracking
    subTitle: 'Bekijk hier of je belasting kunt besparen!', //these are needed for tracking
    subSteps: [{ component: Result, fields: [] }],
    icon: 'PrevicusIcon',
    personalDataPopup: {
      title: 'Persoonlijke gegevens',
      description: 'Vul hieronder je persoonlijke gegevens in, zodat we jouw bezwaar kunnen starten',
      fields: [
        { name: 'firstName', type: 'text', label: 'Voornaam', required: true },
        { name: 'surname', type: 'text', label: 'Achternaam', required: true },
        { name: 'email', type: 'email', label: 'E-mailadres', required: true },
        { name: 'phone', type: 'tel', label: 'Telefoonnummer', required: false },
      ],
    },
  },
];

export const STEPS_VALIDATION_OBJECT = object().shape({
  addressDetails: object().shape({
    address: string().required('Dit veld is verplicht'),
  }),
  livingSituation: object().shape({
    relationshipToProperty: string().required('Dit veld is verplicht'),
    isTransferredAfter2020: string().when('livingSituation.relationshipToProperty', {
      is: (relationshipToProperty: string) =>
        relationshipToProperty !== 'landlord' && relationshipToProperty !== 'multiple-houses',
      // eslint-disable-next-line unicorn/no-thenable
      then: (schema) => schema.required('Dit veld is verplicht'),
    }),
    transferDate: string()
      .test('livingSituation.transferDate', 'De datum mag niet in de toekomst liggen', (value, context) => {
        if (context?.parent?.isTransferredAfter2020 === 'no') {
          return true;
        }

        return !!value && new Date(value) < new Date();
      })
      .test(
        'livingSituation.transferDate',
        'Een eigen aankoopcijfer is enkel bruikbaar als jouw woning de afgelopen 3 jaar aangekocht is.',
        (value, context) => {
          if (context?.parent?.isTransferredAfter2020 === 'no') {
            return true;
          }

          const minimumDate = new Date();
          minimumDate.setFullYear(minimumDate.getFullYear() - 3);
          minimumDate.setDate(1);
          minimumDate.setMonth(0);

          return !!value && new Date(value) > minimumDate;
        }
      )
      .test('livingSituation.transferDate', 'Dit veld is verplicht', (value, context) => {
        if (context?.parent?.isTransferredAfter2020 === 'no') {
          return true;
        }

        return !!value;
      }),

    transferPrice: mixed().test(
      'livingSituation.transferPrice',
      'Dit veld is verplicht en de waarde moet minimaal 0 zijn',
      (value, context) => {
        if (context?.parent?.isTransferredAfter2020 === 'no') {
          return true;
        }

        if (typeof value === 'string' && Number.parseInt(value, 10) < 0) {
          return false;
        }

        return !!value;
      }
    ),
    renovationPrice: mixed().test(
      'livingSituation.renovationPrice',
      'Dit veld is verplicht en de waarde moet minimaal 0 zijn',
      (value, context) => {
        if (context?.parent?.isTransferredAfter2020 === 'no') {
          return true;
        }

        if (typeof value === 'string' && Number.parseInt(value, 10) < 0) {
          return false;
        }

        return true;
      }
    ),
    houseType: string().required('Dit veld is verplicht'),
  }),
  charactaristics: object().shape({
    useSurface: number().typeError('Dit veld is verplicht').required('Dit veld is verplicht'),
    bathroomAge: string().required('Dit veld is verplicht'),
    kitchenAge: string().required('Dit veld is verplicht'),
  }),
  quality: object().shape({
    sustainability: string().required('Dit veld is verplicht'),
    maintananceLevel: string().required('Dit veld is verplicht'),
  }),
  personalData: object().shape({
    firstName: string().required('Dit veld is verplicht'),
    surname: string().required('Dit veld is verplicht'),
    salutation: string().required('Dit veld is verplicht'),
    email: string().email('Vul een geldig e-mailadres in').required('Dit veld is verplicht'),
    phone: string(),
  }),
});
