import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getProgress, setProgress } from 'components/helpers/progress';
import { AllFields, CalculateResponse, Step } from 'components/helpers/types';
import { UseFormReturn, useForm } from 'react-hook-form';
import { scrollToStep } from 'components/helpers/scrollToStep';
import { captureException } from 'components/helpers/captureException';
import { STEPS, STEPS_VALIDATION_OBJECT } from 'components/constants/STEPS';
import { nextStepDisabled } from 'components/helpers/nextStepDisabled';
import { calculateWoz } from 'components/helpers/api';
import { useDefaultValues } from 'hooks/useDefaultValues';
import { yupResolver } from '@hookform/resolvers/yup';
import { hasCurrentStepErrors } from '../components/helpers/hasCurrentStepErrors';
import { parseFormDataForSubmit } from '../components/helpers/parseFormDataForSubmit';
import { setWozResult } from 'components/helpers/cookies';
import { trackEvent } from 'components/helpers/tracking';

export interface PopupType {
  type?: 'personalData' | 'confirmAddress' | 'popup' | 'multipleHouse';
  onClose?: () => void;
  onConfirm?: () => void;
  address?: string;
}

interface StepsContextProperties {
  submitData: () => Promise<CalculateResponse | undefined>;
  currentStep: Step;
  CurrentSubStep: React.ComponentType<any>;
  currentStepIndex: number;
  currentSubStepIndex: number;
  hasPreviousStep: boolean;
  hasNextStep: boolean;
  handleGoToStep: (stepIndex: number) => void;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  isNextStepDisabled: boolean;
  submissionError?: string;
  setSubmissionError: Dispatch<SetStateAction<string | undefined>>;
  calculationSucceeded?: boolean;
  setCalculationSucceeded: Dispatch<SetStateAction<boolean | undefined>>;
  popup?: PopupType;
  formMethods: UseFormReturn<AllFields, undefined, AllFields>;
  hideNextButton?: boolean;
  hidePreviousButton?: boolean;
  resetScan: () => void;
}

const StepsContext = createContext<StepsContextProperties | undefined>(undefined);

export const StepsProvider = ({ children }: { children: React.ReactNode }) => {
  const [submissionError, setSubmissionError] = useState<string>();
  const [calculationSucceeded, setCalculationSucceeded] = useState<boolean>();
  // eslint-disable-next-line react/hook-use-state
  const [{ currentStepIndex, currentSubStepIndex }, setCurrentStep] = useState({
    currentStepIndex: 0,
    currentSubStepIndex: 0,
  });
  const [popup, setPopup] = useState<PopupType>({
    onClose: undefined,
    type: undefined,
  });

  const isFirstRender = useRef(true);

  const formMethods = useForm<AllFields, undefined, AllFields>({
    //TODO: fix this typing
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    resolver: yupResolver(STEPS_VALIDATION_OBJECT),
  });

  const allValues = formMethods.getValues();

  useDefaultValues(formMethods.reset, formMethods.getValues);

  const isNextStepDisabled = useMemo(
    () => nextStepDisabled({ allValues, currentStepIndex, currentSubStepIndex, calculationSucceeded }),
    [allValues, currentStepIndex, currentSubStepIndex, calculationSucceeded]
  );

  const currentStep = useMemo(() => STEPS[currentStepIndex], [currentStepIndex]);

  const CurrentSubStep = useMemo(
    () => currentStep.subSteps[currentSubStepIndex].component,
    [currentStep, currentSubStepIndex]
  );

  const hasPreviousStep = useMemo(
    () => currentStepIndex > 0 || currentSubStepIndex > 0,
    [currentStepIndex, currentSubStepIndex]
  );
  const hasNextStep = useMemo(
    () =>
      currentStepIndex < STEPS.length - 1 ||
      // eslint-disable-next-line unicorn/prefer-at
      currentSubStepIndex < STEPS[STEPS.length - 1].subSteps.length - 1,
    [currentStepIndex, currentSubStepIndex]
  );

  const hidePreviousButton = useMemo(() => currentStep.id === 'result', [currentStep.id]);
  const hideNextButton = useMemo(
    () => currentStep.id === 'calculation' || currentStep.id === 'result',
    [currentStep.id]
  );

  const goToStep = useCallback((stepIndex: number, fieldValues: Partial<AllFields>) => {
    setProgress({ step: stepIndex, subStep: 0, fieldValues });
    setCurrentStep({
      currentStepIndex: stepIndex,
      currentSubStepIndex: 0,
    });
  }, []);

  const errors = formMethods.formState.errors;
  const trigger = formMethods.trigger;

  const goToNextStep = useCallback(
    async (fieldValues: Partial<AllFields>) => {
      const currentStepFields = currentStep.subSteps[currentSubStepIndex].fields;

      await trigger?.(currentStepFields as []);

      const newErrors = formMethods.formState.errors;

      const errorsArePresent = hasCurrentStepErrors(currentStepFields, newErrors);

      if (errorsArePresent) return;

      if (currentSubStepIndex < currentStep.subSteps.length - 1) {
        setProgress({ step: currentStepIndex, subStep: currentSubStepIndex + 1, fieldValues });
        setCurrentStep({
          currentStepIndex: currentStepIndex,
          currentSubStepIndex: currentSubStepIndex + 1,
        });
        window.scrollTo(0, 0);

        trackEvent('goToNextSubStep', {
          title: currentStep.title,
          step: currentStepIndex,
          subStep: currentSubStepIndex + 1,
        });

        return;
      }

      setProgress({ step: currentStepIndex + 1, subStep: 0, fieldValues });

      scrollToStep(currentStepIndex + 1);
      setCurrentStep({
        currentStepIndex: currentStepIndex + 1,
        currentSubStepIndex: 0,
      });
      trackEvent('goToNextStep', { title: currentStep.title, step: currentStepIndex + 1 });
      return;
    },
    [currentStep.subSteps, currentStep.title, currentStepIndex, currentSubStepIndex, errors, trigger]
  );

  const goToPreviousStep = useCallback(
    (fieldValues: Partial<AllFields>) => {
      if (currentSubStepIndex > 0) {
        setProgress({ step: currentStepIndex, subStep: currentSubStepIndex - 1, fieldValues });
        trackEvent('goToPreviousSubstep', {
          title: currentStep.title,
          step: currentStepIndex,
          subStep: currentSubStepIndex - 1,
        });

        return setCurrentStep({
          currentStepIndex: currentStepIndex,
          currentSubStepIndex: currentSubStepIndex - 1,
        });
      }
      setProgress({
        step: currentStepIndex - 1,
        subStep: STEPS[currentStepIndex - 1].subSteps.length - 1,
        fieldValues,
      });
      trackEvent('goToPreviousStep', {
        title: currentStep.title,
        step: currentStepIndex - 1,
        subStep: STEPS[currentStepIndex - 1].subSteps.length - 1,
      });
      return setCurrentStep({
        currentStepIndex: currentStepIndex - 1,
        currentSubStepIndex: STEPS[currentStepIndex - 1].subSteps.length - 1,
      });
    },
    [currentStep.title, currentStepIndex, currentSubStepIndex]
  );

  const handleNextStep = useCallback(() => {
    if (currentStepIndex === 0) {
      setPopup({
        type: 'confirmAddress',
        address: allValues?.addressDetails?.address,
        onConfirm: () => {
          setPopup({ onClose: undefined, type: undefined });
          goToNextStep(allValues);
        },
        onClose: () => {
          setPopup({ onClose: undefined, type: undefined });
        },
      });
      return;
    }

    if (currentStepIndex === 1 && formMethods.watch('livingSituation.relationshipToProperty') === 'landlord') {
      setPopup({
        type: 'popup',
        onClose: () => setPopup({ onClose: undefined, type: undefined }),
      });
      return;
    }

    if (currentStepIndex === 1 && formMethods.watch('livingSituation.relationshipToProperty') === 'multiple-houses') {
      setPopup({
        type: 'multipleHouse',
        onClose: () => setPopup({ onClose: undefined, type: undefined }),
      });
      return;
    }

    if (currentStepIndex === 5) {
      setPopup({
        type: 'personalData',
        onClose: () => setPopup({ onClose: undefined, type: undefined }),
      });
      return;
    }

    goToNextStep(allValues);
  }, [allValues, currentStepIndex, formMethods, goToNextStep]);

  const handlePreviousStep = useCallback(() => {
    const currentValues = allValues;

    goToPreviousStep(currentValues);
    scrollToStep(currentStepIndex - 1);
  }, [allValues, currentStepIndex, goToPreviousStep]);

  const handleGoToStep = useCallback(
    (index: number, overrideValues?: Partial<AllFields>) => {
      const currentValues = allValues;

      trackEvent('goToStep', { title: currentStep.title, step: index });

      goToStep(index, overrideValues || currentValues);
    },
    [allValues, currentStep.title, goToStep]
  );

  useEffect(() => {
    const progress = getProgress();

    if (currentStepIndex === 0 && !!progress && progress.step > 0 && isFirstRender.current) {
      handleGoToStep(progress.step, progress.fieldValues);
      isFirstRender.current = false;
    }
  }, [currentStepIndex, goToStep, handleGoToStep]);

  const submitData = useCallback(async () => {
    const allData = parseFormDataForSubmit(getProgress()?.fieldValues);
    try {
      if (!allData?.postcode) {
        throw new Error('Postcode is missing in calculation');
      }

      if (!allData?.house_number) {
        throw new Error('House number is missing in calculation');
      }

      const response = await calculateWoz({
        ...allData,
        postcode: allData?.postcode || '',
        house_number: allData?.house_number || '',
      });

      if (!response.uuid) throw new Error('No uuid returned from calculation');

      trackEvent('submitCalculation');

      setWozResult(
        JSON.stringify({
          uuid: response?.uuid,
          address: response?.address,
          range: response?.range,
        })
      );
      setCalculationSucceeded(true);

      return response;
    } catch (error) {
      captureException(error, { tags: { fn: 'submitData' } });
      setSubmissionError('Er is iets fout gegaan, probeer het later opnieuw');
    }
  }, []);

  const resetScan = useCallback(() => {
    formMethods.reset();
    setProgress({ step: 0, subStep: 0, fieldValues: {} });
    setCurrentStep({ currentStepIndex: 0, currentSubStepIndex: 0 });
  }, [formMethods]);

  const value = useMemo(
    () => ({
      submitData,
      currentStep,
      CurrentSubStep,
      currentStepIndex,
      currentSubStepIndex,
      handlePreviousStep,
      hasNextStep,
      handleGoToStep,
      handleNextStep,
      goToPreviousStep,
      isNextStepDisabled,
      submissionError,
      setSubmissionError,
      calculationSucceeded,
      setCalculationSucceeded,
      popup,
      hasPreviousStep,
      formMethods,
      hideNextButton,
      hidePreviousButton,
      resetScan,
    }),
    [
      submitData,
      currentStep,
      currentStepIndex,
      CurrentSubStep,
      currentSubStepIndex,
      handleNextStep,
      goToPreviousStep,
      handleGoToStep,
      hasNextStep,
      handlePreviousStep,
      isNextStepDisabled,
      submissionError,
      setSubmissionError,
      calculationSucceeded,
      setCalculationSucceeded,
      popup,
      hasPreviousStep,
      formMethods,
      hideNextButton,
      hidePreviousButton,
      resetScan,
    ]
  );

  return <StepsContext.Provider value={value}>{children}</StepsContext.Provider>;
};

export const useSteps = () => {
  const context = useContext<StepsContextProperties | undefined>(StepsContext);

  if (!context) {
    throw new Error('useSteps must be used within a StepsProvider');
  }

  return context;
};
