import { useFormContext } from 'react-hook-form';
import { Input } from 'components/Input';
import { STEPS } from 'components/constants/STEPS';
import { getErrorFromFormState } from 'components/helpers/getErrorFromFormState';
import { Select } from 'components/Select';
import styles from './Charactaristics.module.css';

const AGE_OPTIONS = [
  {
    label: '0-5 jaar',
    value: '<=5',
  },
  {
    label: '6-10 jaar',
    value: '6-10',
  },
  {
    label: '11-15 jaar',
    value: '11-15',
  },
  {
    label: '16-20 jaar',
    value: '16-20',
  },
  { label: '21-30 jaar', value: '21-30' },
  {
    label: '31 jaar of ouder',
    value: '>31',
  },
];

export const Charactaristics = () => {
  const { setValue, watch, formState } = useFormContext<any>();

  const [useSurfaceFieldName, bathroomAgeFieldName, kitchenAgeFieldName] = STEPS[2].subSteps[0].fields;

  return (
    <div className={styles.container}>
      <Input
        error={getErrorFromFormState(formState, useSurfaceFieldName)}
        name={useSurfaceFieldName}
        type="number"
        label="Wat is de woonoppervlakte van de woning?"
        info="De vermelde vierkante meters zijn afkomstig uit de Basisregistratie Adressen en Gebouwen (BAG). De meting moet binnenshuis worden uitgevoerd, van muur tot muur, en schuine daken kunnen als woonoppervlakte worden meegerekend vanaf een hoogte van 1,50 meter."
        value={watch(useSurfaceFieldName)}
        after="m2"
        onChange={(event) => setValue(useSurfaceFieldName, Number.parseInt(event.target.value, 10))}
      />
      <Select
        options={AGE_OPTIONS}
        error={getErrorFromFormState(formState, bathroomAgeFieldName)}
        name={bathroomAgeFieldName}
        label="Wat is de leeftijd van de badkamer?"
        value={watch(bathroomAgeFieldName)}
        onChange={(event) => setValue(bathroomAgeFieldName, event.target.value)}
      />
      <Select
        options={AGE_OPTIONS}
        error={getErrorFromFormState(formState, kitchenAgeFieldName)}
        name={kitchenAgeFieldName}
        label="Wat is de leeftijd van de keuken?"
        value={watch(kitchenAgeFieldName)}
        onChange={(event) => setValue(kitchenAgeFieldName, event.target.value)}
      />
    </div>
  );
};
